import MuiTypography, { TypographyProps } from '@mui/material/Typography';

export interface NuiTypographyProps extends TypographyProps {
  variant?: any;
}

const NuiTypography = (props: NuiTypographyProps) => {
  return <MuiTypography variant="body" {...props} />;
};

export default NuiTypography;
