import { Box, keyframes } from '@mui/material';
import { isEmbed } from '../../helpers/embedHelper';
import { getAppTheme } from '../../helpers/themeHelper';
import BackgroundDefault from './BackgroundDefault';
import {
  BackgroundHolidays,
  BackgroundHappyHolidays,
} from './BackgroundHolidays';
import { useEffect, useState } from 'react';

const animBackground = (scale: number[]) => keyframes`
  0% {
    opacity: 0;
    transform: scale(${scale[0]});
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(${scale[1]});
  }
`;

const Comp: React.FC<{ theme: string }> = ({ theme }) => {
  switch (theme) {
    case 'default':
      return <BackgroundDefault />;
    case 'holidays':
      return <BackgroundHolidays />;
    default:
      console.log(`Nothing for theme ${theme}`);
      return null;
  }
};

interface BackgroundProps {
  state: string;
}

const Background: React.FC<BackgroundProps> = ({ state }) => {
  const [active, setActive] = useState(false);
  const embed = isEmbed();
  const backgroundAnimScales = embed ? [1.2, 1.4] : [0.9, 1];
  const isInitial = state === 'initial';
  const opacities = embed ? [0.05, 0.2] : [0.2, 1];
  const theme = getAppTheme();

  // Reset animation on theme change
  useEffect(() => {
    setActive(false);
    const timeout = setTimeout(() => {
      setActive(true);
    }, 100);
    return () => clearTimeout(timeout);
  }, [theme]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: isInitial ? opacities[1] : opacities[0],
        transition: 'opacity 1s',
      }}>
      {/* Background */}
      <Box
        sx={(theme) => ({
          opacity: 0,
          animation: active
            ? `${animBackground(backgroundAnimScales)} 10s forwards`
            : null,
          height: '120vh',
          [theme.breakpoints.up('lg')]: {
            height: '150vh',
          },
        })}>
        <Comp theme={theme} />
      </Box>

      {/* Happy Holidays for Holidays */}
      {theme === 'holidays' && <BackgroundHappyHolidays />}
    </Box>
  );
};

export default Background;
