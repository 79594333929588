import { createTheme } from '@mui/material';
import globals from './globals';

const theme = createTheme();
const font: any = {
  regular: { font: 'var(--font-regular)' },
  medium: { font: 'var(--font-medium)' },
  demi: { font: 'var(--font-demi)' },
  bold: { font: 'var(--font-bold)' },
};

const _f = (id: string, size: number, sizeLg?: number) => {
  const isHeadline = id.startsWith('headline-');
  const isBody = id.startsWith('body');
  const isCaption = id.startsWith('caption');
  const fontSize = globals.units(size);
  const lineHeight = `${isHeadline ? 130 : 150}%`;
  const color =
    isBody || isCaption ? globals.colors.white80 : globals.colors.white;
  const params = { fontSize, lineHeight, color };
  const responsive = sizeLg && {
    [theme.breakpoints.up('lg')]: {
      fontSize: globals.units(sizeLg),
    },
  };
  return {
    [id]: { ...font.medium, ...params, ...responsive },
    [`${id}-light`]: { ...font.regular, ...params, ...responsive },
    [`${id}-demi`]: { ...font.demi, ...params, ...responsive },
    [`${id}-bold`]: { ...font.bold, ...params, ...responsive },
  };
};

const typography: any = {
  fontFamily: `'NapsterSans', system-ui, sans-serif`,
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 700,
  fontWeightBold: 1000,
  allVariants: {
    color: globals.colors.white,
  },
  ..._f('headline-xxlarge', 18),
  ..._f('headline-xlarge', 12),
  ..._f('headline-large', 9),
  ..._f('headline', 6),
  ..._f('title', 4.5, 5),
  ..._f('title-small', 4, 4.5),
  ..._f('body', 3.5, 4),
  ..._f('caption', 3, 3.5),
  ..._f('caption-small', 2.5, 3),
  ..._f('caption-smallest', 2.5),
};

export default typography;
