import { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const fadeInterval = 10;
const fadeStep = 0.05;

const _rnd = (num: number) => Math.round(num * 100) / 100;

const Player: React.FC<{
  queue: QueueTypes;
  index: number;
  playing: boolean;
  onEnd: Function;
  onPlay: Function;
  onPause: Function;
  onNext: Function;
  onPrevious: Function;
  onProgress: Function;
  onBuffer: Function;
  onBufferEnd: Function;
}> = ({
  queue,
  index,
  playing,
  onEnd,
  onPlay,
  onPause,
  onNext,
  onPrevious,
  onProgress,
  onBuffer,
  onBufferEnd,
}) => {
  const playerRef = useRef<any>(null);
  const interval = useRef<any>(null);
  const [volume, setVolume] = useState(0);
  const [active, setActive] = useState(false);

  // Fade volume and control playback
  useEffect(() => {
    if (playing) {
      setActive(true);
      interval.current = setTimeout(() => {
        const newVol = _rnd(volume + fadeStep);
        setVolume(newVol >= 1 ? 1 : newVol);
      }, fadeInterval);
    } else {
      interval.current = setTimeout(() => {
        const newVol = _rnd(volume - fadeStep);
        if (volume <= 0) {
          setVolume(0);
          setActive(false);
        } else {
          setVolume(newVol);
        }
      }, fadeInterval);
    }
    return () => clearTimeout(interval.current);
  }, [playing, volume]);

  // Capture Media session keyboard events
  useEffect(() => {
    if (!navigator.mediaSession) return;
    navigator.mediaSession.setActionHandler('play', () => onPlay());
    navigator.mediaSession.setActionHandler('pause', () => onPause());
    navigator.mediaSession.setActionHandler('nexttrack', () => onNext());
    navigator.mediaSession.setActionHandler('previoustrack', () =>
      onPrevious(),
    );
  }, [onPlay, onPause, onNext, onPrevious]);

  // Update media session content on track change
  useEffect(() => {
    if (!queue || queue.length < 1) return;
    const track = queue[index];
    const { title, artist, album, urls } = track;
    if (!navigator.mediaSession) return;
    navigator.mediaSession.metadata = new window.MediaMetadata({
      title,
      artist,
      album,
      artwork: [
        {
          src: urls?.artwork || '',
          type: 'image/jpg',
        },
      ],
    });
  }, [queue, index]);

  // Fix: https://github.com/CookPete/react-player/issues/618
  useEffect(() => {
    if (!playerRef.current || !playing || index !== 0) return;
    playerRef.current.getInternalPlayer().play();
  }, [index, playing]);

  const activeTrack = queue?.[index];

  return (
    <ReactPlayer
      ref={playerRef}
      url={activeTrack?.urls?.preview}
      playing={active}
      playsinline
      controls={false}
      volume={volume}
      onEnded={() => onEnd()}
      onError={(e) => console.error(e)}
      onBuffer={() => onBuffer()}
      onBufferEnd={() => onBufferEnd()}
      onProgress={(p) => {
        onProgress(p.played);
      }}
      style={{ display: 'none' }}
    />
  );
};

export default Player;
