const LoadingCandycane = () => (
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48.5465 25.424C47.3417 28.0032 43.9712 33.5698 48.8027 36.8683C53.6342 40.1668 57.1953 35.4107 58.9229 32.3506C60.8217 28.9743 63.0346 24.6282 63.0346 24.6282C63.0346 24.6282 67.5819 15.7897 75.5429 21.8205C81.1352 26.0585 77.3533 32.1072 73.6894 42.4339C71.691 48.0705 48.8357 108.082 48.8357 108.082C48.8357 108.082 45.5744 116.143 50.3022 118.258C55.0299 120.374 57.7257 118.45 60.7787 110.351C63.8317 102.252 88.0193 37.0853 88.0193 37.0853C88.0193 37.0853 93.429 25.1913 86.8264 17.0378C80.2238 8.88431 66.1549 3.52197 55.9155 14.1709C52.0794 18.1607 49.9579 22.3943 48.5471 25.4187L48.5465 25.424Z"
      fill="#7C3FFF"
    />
    <path
      className="anim-fill"
      d="M50.3071 118.264C52.7802 119.367 54.6956 119.367 56.398 117.96C50.8923 112.293 50.9864 106.653 50.5263 103.66C49.4707 106.435 48.8412 108.082 48.8412 108.082C48.8412 108.082 45.58 116.144 50.3077 118.259L50.3071 118.264Z"
      fill="#FF5B4E"
    />
    <path
      d="M59.07 97.9374C56.9837 94.0626 56.481 90.3649 56.3915 88.2386C55.4975 90.5852 54.6473 92.8196 53.8613 94.8964C53.6418 97.3881 53.7689 101.126 55.6608 104.63C57.8631 108.713 59.8291 109.695 60.3972 111.357C60.5264 111.036 60.6567 110.705 60.7835 110.357C61.1448 109.393 61.8148 107.611 62.7016 105.232C62.3823 103.374 61.0997 101.695 59.0753 97.938L59.07 97.9374Z"
      fill="#FF5B4E"
    />
    <path
      className="anim-fill"
      d="M65.0576 80.941C63.2771 77.6395 62.6511 74.4594 62.4505 72.2789C61.5618 74.6261 60.6605 76.9931 59.7729 79.3297C59.7299 81.7074 60.0895 84.7481 61.6484 87.6337C64.1035 92.1892 66.2695 92.8837 66.5225 94.9688C67.2325 93.0592 67.9856 91.0427 68.7633 88.9438C68.6845 86.7656 67.3407 85.1654 65.0634 80.9363L65.0576 80.941Z"
      fill="#FF5B4E"
    />
    <path
      d="M71.1784 65.3176C69.2736 61.7855 68.6906 58.3977 68.5325 56.2164C67.7157 58.3854 66.8287 60.7168 65.9096 63.1509C65.8012 65.596 66.0859 68.8941 67.7634 72.0149C69.9964 76.1598 71.9925 77.1071 72.5236 78.8182C73.2847 76.776 74.0475 74.7181 74.8102 72.6601C74.5089 70.7821 73.2153 69.1076 71.1726 65.3223L71.1784 65.3176Z"
      fill="#FF5B4E"
    />
    <path
      className="anim-fill"
      d="M77.4222 49.6705C75.2528 45.9302 74.6518 42.3601 74.5073 40.2015C74.2348 40.9262 73.9659 41.6673 73.6937 42.4398C73.4152 43.2223 72.7407 45.0454 71.7764 47.6075C71.6959 50.0395 72.0455 53.2755 73.824 56.3433C75.881 59.8914 77.7366 61.1157 78.5977 62.4636C79.4344 60.2065 80.2483 58.0159 81.0254 55.9224C80.4261 54.4057 79.1872 52.7156 77.4169 49.6699L77.4222 49.6705Z"
      fill="#FF5B4E"
    />
    <path
      d="M78.3591 27.8359C78.2657 29.5822 77.7024 31.5603 76.9018 33.8265C78.4418 35.9833 81.4494 41.0372 83.3484 49.6725C84.2879 47.145 85.1246 44.8879 85.8208 43.0087C85.2754 39.8689 83.4921 32.4553 78.3596 27.8307L78.3591 27.8359Z"
      fill="#FF5B4E"
    />
    <path
      className="anim-fill"
      d="M88.6289 19.9279C83.1398 17.7381 78.4699 19.8682 75.6355 21.8994C77.048 22.9975 77.8425 24.2217 78.1794 25.61C83.4329 23.7323 87.3192 24.3636 90.0619 25.7288C89.9345 23.8108 89.5119 21.8246 88.6289 19.9279Z"
      fill="#FF5B4E"
    />
    <path
      d="M73.3232 8.67278C69.7562 11.8685 68.8646 16.1624 68.9248 19.8295C70.3443 19.549 72.002 19.7285 73.8849 20.7435C73.8074 16.231 77.1437 13.2604 80.3256 11.4567C78.1737 10.1747 75.7987 9.19824 73.3232 8.67278Z"
      fill="#FF5B4E"
    />
    <path
      d="M51.9635 19.1566C50.7693 20.9782 49.8427 22.7271 49.1041 24.2518C49.0078 26.6822 49.3144 29.9242 50.9659 32.9893C52.1601 35.2052 53.2861 36.5067 54.1531 37.4944C55.7441 36.7438 57.0579 35.2478 58.0335 33.7905C58.0335 33.7905 52.2704 28.7547 51.9582 19.1561L51.9635 19.1566Z"
      fill="#FF5B4E"
    />
    <path
      className="anim-fill"
      d="M60.8681 28.7776C62.0708 26.5219 63.0286 24.6277 63.0286 24.6277C63.0286 24.6277 63.7026 23.3138 64.9921 22.0486C64.7216 20.8371 64.3311 19.3529 63.9132 17.3725C63.2302 14.128 63.3803 11.2364 63.7139 9.12273C62.8508 9.40871 61.9906 9.76926 61.1431 10.2107L61.0442 10.3436C61.0442 10.3436 55.9142 14.1761 58.7503 22.8304C59.7027 25.7318 60.2982 27.4868 60.8681 28.7776Z"
      fill="#FF5B4E"
    />
  </svg>
);
export default LoadingCandycane;
