import { createTheme } from '@mui/material';
import globals from './globals';
import typography from './typography';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: globals.colors.black,
      paper: globals.colors.bgLight,
    },
    primary: {
      main: globals.colors.secondaryCyan,
      contrastText: globals.colors.black,
    },
    secondary: {
      main: globals.colors.secondaryCyan,
      contrastText: globals.colors.black,
    },
    text: {
      primary: globals.colors.white,
      secondary: globals.colors.white80,
      disabled: globals.colors.white40,
    },
    info: {
      main: globals.colors.secondaryCyan,
      contrastText: globals.colors.black,
    },
    error: {
      main: globals.colors.semanticErrorLight,
      contrastText: globals.colors.black,
    },
    warning: {
      main: globals.colors.semanticWarningLight,
      contrastText: globals.colors.black,
    },
    success: {
      main: globals.colors.semanticSuccessLight,
      contrastText: globals.colors.black,
    },
    divider: globals.colors.white10,
  },
  typography,
  shape: {
    borderRadius: 8,
  },
  spacing: globals.units,
  components: {
    // Disable scrolling (as we scroll in the dialog)
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     body: {
    //       overflow: 'hidden'
    //     }
    //   }
    // },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: globals.colors.bgDark,
        },
      },
    },
  },
});

export default theme;
