import MuiCircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { globals } from '../../theme';

export interface NuiCircularProgressProps extends CircularProgressProps {}

const NuiCircularProgress = styled((props: NuiCircularProgressProps) => {
  return <MuiCircularProgress {...props} />;
})<NuiCircularProgressProps>(({ theme }) => ({
  '&.MuiCircularProgress-colorPrimary': {
    color: globals.colors.white,
  },
  '&.MuiCircularProgress-colorSecondary': {
    color: globals.colors.secondaryCyan,
  },
}));

export default NuiCircularProgress;
