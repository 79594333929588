const LoadingNote = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" fill="none">
    <path
      d="M99.9798 83.7065L91.6342 21.76L82.7244 24.6539L42.5286 37.6665L37.227 39.3867L43.8188 87.8754C41.9441 87.6527 39.9887 87.8147 38.0535 88.4421C31.3005 90.6277 27.4704 97.5286 29.5064 103.843C31.5424 110.177 38.6583 113.516 45.4114 111.33C51.2774 109.428 54.9261 103.984 54.4624 98.4393H54.5429L48.052 50.2542L84.5586 38.4356L89.2758 73.1628C87.4011 72.9402 85.4458 73.1021 83.5106 73.7295C76.7576 75.9151 72.9273 82.816 74.9633 89.1301C76.9992 95.4643 84.1152 98.8035 90.8682 96.6179C96.7343 94.7156 100.383 89.2718 99.9192 83.7267H100L99.9798 83.7065Z"
      fill="#2259FF"
    />
    <path
      className="anim-fill"
      d="M99.3554 78.7068L91.0098 16.7603L82.1 19.6542L41.9042 32.6668L36.6026 34.387L43.1944 82.8757C41.3197 82.653 39.3643 82.815 37.4291 83.4424C30.6761 85.628 26.8461 92.5289 28.882 98.8429C30.918 105.177 38.0339 108.516 44.787 106.331C50.653 104.428 54.3017 98.9846 53.838 93.4396H53.9185L47.4276 45.2545L83.9342 33.4359L88.6515 68.1631C86.7767 67.9405 84.8214 68.1024 82.8862 68.7298C76.1332 70.9154 72.3029 77.8163 74.3389 84.1304C76.3749 90.4646 83.4908 93.8038 90.2438 91.6182C96.1099 89.7159 99.7585 84.2721 99.2949 78.727H99.3756L99.3554 78.7068Z"
      fill="#FD81F5"
    />
    <path
      d="M87.2226 21.76L41.772 36.5278L42.0395 38.481L87.4898 23.7487L87.2226 21.76Z"
      fill="#825DC4"
    />
    <path
      d="M87.2226 25.6L41.772 40.3678L42.0395 42.321L87.4898 27.5886L87.2226 25.6Z"
      fill="#825DC4"
    />
  </svg>
);
export default LoadingNote;
