import { useEffect, useState } from 'react';
import { Box, keyframes } from '@mui/material';
import NuiTypography from '../NuiTypography';
import { useLocale } from '../LocaleProvider';
import { useAppThemeColors } from '../../helpers/themeHelper';
import { shuffleArray } from '../../helpers/utils';
import { globals } from '../../theme';

const animHint = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
const animHintText = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;
const nodeCount = 4;

const Hint: React.FC<{
  text: string;
  themeColors: any;
  animDelay: number;
  onHint: Function | undefined;
}> = ({ text, animDelay, themeColors, onHint }) => {
  const [active, setActive] = useState(false);
  return (
    <Box
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={() => onHint && onHint(text)}
      sx={(theme) => ({
        padding: `${theme.spacing(2)} 0`,
        opacity: 0,
        animation: `${animHint} 0.6s ${animDelay}ms forwards`,
        '&:hover': {
          cursor: 'pointer',
        },
      })}>
      <NuiTypography
        variant="body-bold"
        sx={{
          userSelect: 'none',
          color: active ? themeColors.primary : globals.colors.white,
          transition: 'color 0.3s',
          opacity: 0.4,
          animation: `${animHintText} 2s ${animDelay}ms linear infinite`,
        }}>
        {text}
      </NuiTypography>
    </Box>
  );
};

interface HintsProps {
  onHint?: Function | undefined;
}

const Hints: React.FC<HintsProps> = ({ onHint }) => {
  const [nodes, setNodes] = useState<null | string[]>(null);

  const text = useLocale().text;
  const themeColors = useAppThemeColors();

  useEffect(() => {
    if (!text) return;
    const newNodes: string[] = shuffleArray(text.INPUT_HINTS).slice(
      0,
      nodeCount,
    );
    setNodes(newNodes);
  }, [text]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
      }}>
      {nodes?.map((node: any, index: number) => {
        const animDelay = 600 + index * 100;
        return (
          <Hint
            key={index}
            text={node}
            animDelay={animDelay}
            themeColors={themeColors}
            onHint={onHint}
          />
        );
      })}
    </Box>
  );
};

export default Hints;
