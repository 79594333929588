import { useState } from 'react';
import { Box, keyframes } from '@mui/material';
import { getAppThemeColor } from '../../helpers/themeHelper';

const animStar = (duration: number, delay: number) =>
  `${keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`} ${duration}ms ${delay}ms ease-out forwards`;

const spread = 2;
const _size = () => 24 + Math.random() * window.innerHeight * 0.1;
const _pos = () => {
  const { innerWidth, innerHeight } = window;
  const x = (innerWidth / 2) * spread;
  const y = (innerHeight / 2) * spread;
  const pos = [x / 2 - x * Math.random(), y / 2 - y * Math.random()];
  return pos;
};
const _duration = () => 600 + Math.random() * 600;
const _delay = () => Math.random() * 600;

const Star = () => {
  const [size] = useState<number>(_size());
  const [pos] = useState<number[]>(_pos());
  const [duration] = useState<number>(_duration());
  const [delay] = useState<number>(_delay());
  const [x, y] = pos;

  return (
    <Box
      sx={{
        position: 'absolute',
        width: size,
        height: size,
        transformOrigin: 'center',
        transform: `translate(${x}px, ${y}px)`,
        svg: {
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: 0,
          animation: animStar(duration, delay),
        },
      }}>
      <svg
        width="128"
        height="128"
        viewBox="0 0 128 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M128 64C92.6604 64 64 92.6604 64 128C64 92.6604 35.3396 64 0 64C35.3396 64 64 35.3396 64 0C64 35.3396 92.6604 64 128 64Z"
          fill={getAppThemeColor()}
        />
      </svg>
    </Box>
  );
};

const count = 16;

const Stars: React.FC = () => {
  const stars = Array.from({ length: count }, (_, i) => i);
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        pointerEvents: 'none',
      }}>
      {stars.map((_, index) => (
        <Star key={index} />
      ))}
    </Box>
  );
};

export default Stars;
