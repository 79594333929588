import { CookieExpirePeriod } from '../@typings/Cookies/Cookie.enum';

const CookieAcceptanceName = 'napsterCookieAccpted';

export const originalCookieDescriptor =
  Object.getOwnPropertyDescriptor(Document.prototype, 'cookie') ||
  Object.getOwnPropertyDescriptor(HTMLDocument.prototype, 'cookie');

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return null;
};

export const setCookie = ({
  name,
  value,
  expireValue = 7,
  expirePeriod = CookieExpirePeriod.days,
  // checkForCookieAcceptance,
  domain,
}: {
  name: string;
  value: string;
  expireValue?: number;
  expirePeriod?: CookieExpirePeriod;
  checkForCookieAcceptance?: boolean;
  domain?: string;
}) => {
  // if (
  //   checkForCookieAcceptance &&
  //   getCookie(CookieAcceptanceName) === null &&
  //   name !== CookieAcceptanceName
  // ) {
  //   return;
  // }
  const date = new Date();
  if (expirePeriod === CookieExpirePeriod.minutes) {
    date.setTime(date.getTime() + expireValue * 60 * 1000);
  } else {
    date.setTime(date.getTime() + expireValue * 24 * 60 * 60 * 1000);
  }
  const expires = `expires=${date.toUTCString()}`;
  (originalCookieDescriptor as any).set.call(
    document,
    `${name}=${value};${expires};path=/${domain ? `;domain=${domain}` : ''}`,
  );
};

export const deleteCookie = (name: string, domain?: string) => {
  (originalCookieDescriptor as any).set.call(
    document,
    `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/${
      domain ? `;domain=${domain}` : ''
    }`,
  );
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    deleteCookie(name);
  }
};

export const overrideDocumentCookie = () => {
  try {
    Object.defineProperty(document, 'cookie', {
      get: () => (originalCookieDescriptor as any).get.call(document),
      set: (val: any) => {
        if (
          !val.startsWith('_px') &&
          getCookie(CookieAcceptanceName) === null
        ) {
          return;
        }
        (originalCookieDescriptor as any).set.call(document, val);
      },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const acceptCookies = () => {
  setCookie({
    name: CookieAcceptanceName,
    value: 'Yes',
    expireValue: 365 * 100,
    expirePeriod: CookieExpirePeriod.days,
  });
};

export const cookiesAccepted = () => {
  const accepted = getCookie(CookieAcceptanceName);
  return accepted !== null ? true : undefined;
};
