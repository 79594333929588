import { Box } from '@mui/material';

interface LabsCardContentProps {
  badge?: boolean;
  state: string;
  children?: React.ReactNode;
}

const LabsCardContent: React.FC<LabsCardContentProps> = ({
  state,
  children,
}) => {
  const isPlaylist =
    state === 'playlist' || state === 'saving' || state === 'saved';
  return (
    <Box
      sx={(theme) => ({
        flex: 1,
        padding: isPlaylist
          ? `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(16)}`
          : `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(6)}`,
        overflowY: 'auto',
      })}
    >
      {children}
    </Box>
  );
};

export default LabsCardContent;
