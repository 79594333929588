import { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';

import { useLocale } from '../LocaleProvider';
import { getAppTheme, useAppThemeColors } from '../../helpers/themeHelper';
import { shuffleArray } from '../../helpers/utils';
import LoadingArtwork from './LoadingArtwork';
import NuiTypography from '../NuiTypography';
import NuiLinearProgress from '../NuiLinearProgress/';
import { globals } from '../../theme';

const loadingTimeSecs = 16;
const loadingTimeSteps = 100;
const loadingTimeInterval = (loadingTimeSecs * 1000) / loadingTimeSteps;

const artworksThemes: any = {
  default: ['boombox', 'note', 'keyboard', 'planet', 'vinyl'],
  holidays: ['candycane', 'present', 'bauble', 'keyboard'],
};

const messageCount = 3;

const Loading: React.FC = () => {
  const [messages, setMessages] = useState<null | string[]>(null);
  const text = useLocale().text;
  const themeColors = useAppThemeColors();
  const appTheme = getAppTheme();

  const [progress, setProgress] = useState(0);
  const [artwork, setArtwork] = useState<string | null>(null);
  const [artworks, setArtworks] = useState<null | string[]>(null);
  const [message, setMessage] = useState<null | any>(null);

  // Set messages
  useEffect(() => {
    const newMessages: any[] = shuffleArray(text.LOADING_MESSAGES);
    setMessages(newMessages);
    setMessage(newMessages[0]);
  }, [text]);

  // Set artworks
  useEffect(() => {
    if (!appTheme) return;
    const newArtworks = shuffleArray(artworksThemes[appTheme]);
    setArtworks(newArtworks);
  }, [appTheme]);

  // Incremement progress
  useEffect(() => {
    if (progress >= 1) return setProgress(1);
    const timeout = setTimeout(() => {
      const newProgress = progress + 1 / loadingTimeSteps;
      setProgress(newProgress);
    }, loadingTimeInterval);
    return () => clearTimeout(timeout);
  }, [progress]);

  // Update initial messages
  useEffect(() => {
    if (!messages || progress >= 1 || !artworks) return;
    const index = Math.floor(progress * messageCount);
    setArtwork(artworks[index]);
    setMessage(messages[index]);
  }, [progress, messages, artworks]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {/* Graphic */}
      {artwork && <LoadingArtwork artwork={artwork} />}
      {message && (
        <Stack spacing={2} marginBottom={12}>
          {/* Title */}
          <NuiTypography variant="headline-bold" textAlign="center">
            {message.TITLE}
          </NuiTypography>
          {/* Description */}
          <NuiTypography
            variant="body"
            textAlign="center"
            color={globals.colors.white80}>
            {message.DESCRIPTION}
          </NuiTypography>
        </Stack>
      )}
      <Box sx={{ width: '100%' }}>
        <NuiLinearProgress
          variant="determinate"
          value={progress * 100}
          sx={{
            '&.MuiLinearProgress-root.MuiLinearProgress-colorPrimary .MuiLinearProgress-bar':
              {
                backgroundColor: themeColors.primary,
              },
          }}
        />
      </Box>
    </Box>
  );
};

export default Loading;
