const LoadingPresent = () => (
  <svg fill="none" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
    <path
      className="anim-fill"
      d="m106.056 36.9312h-84.2689c-1.5393 0-2.7871 1.2478-2.7871 2.7871v12.3944c0 1.5392 1.2478 2.7871 2.7871 2.7871h84.2689c1.539 0 2.787-1.2479 2.787-2.7871v-12.3944c0-1.5393-1.248-2.7871-2.787-2.7871z"
      fill="#2259ff"
    />
    <path
      className="anim-fill"
      d="m96.2734 37.7295h-64.7031c-1.5393 0-2.7871 1.2478-2.7871 2.7871v68.6964c0 1.539 1.2478 2.787 2.7871 2.787h64.7031c1.5393 0 2.7871-1.248 2.7871-2.787v-68.6964c0-1.5393-1.2478-2.7871-2.7871-2.7871z"
      fill="#2259ff"
    />
    <g fill="#fef5d2">
      <path d="m99.0605 71.8301h-70.2773v7.5867h70.2773z" />
      <path d="m69.3441 112-1.5732-74.2705h-4.9753l-.0959.7108-4.1128 73.5597z" />
      <path d="m62.6681 36.6753c-3.7215 0-14.2311 2.4358-25.0842 2.4358-10.853 0-9.6631-9.9826-9.6631-9.9826s-1.9406-11.7475 5.8458-12.7457c7.7864-.9983 15.8044-.0879 21.754 3.2503s7.2673 7.451 8.9284 11.7714l-1.7888 5.2788z" />
      <path d="m63.8014 41.5472c-5.8058 1.0062-13.153 30.355-13.153 30.355l-11.7714-6.4927s5.103-19.5898 19.8054-27.1126l5.1111 3.2503z" />
      <path d="m65.5914 36.6753c3.7215 0 14.2312 2.4358 25.0843 2.4358 10.8533 0 9.6633-9.9826 9.6633-9.9826s1.94-11.7475-5.846-12.7457c-7.7864-.9983-15.8044-.0879-21.754 3.2503-5.9497 3.3382-7.2674 7.451-8.9285 11.7714l1.7889 5.2788z" />
      <path d="m64.4561 41.5472c5.8058 1.0062 13.153 30.355 13.153 30.355l11.7714-6.4927s-5.103-19.5898-19.8054-27.1126l-5.1111 3.2503z" />
    </g>
    <path
      d="m63.8015 33.3133s-10.925-15.3412-35.8894-10.0544c-.4552 3.0107.016 5.8697.016 5.8697s-.0479.3833-.0399.9983c5.3586-2.5396 19.0228-6.9479 35.9133 3.1944z"
      fill="#000"
      opacity=".2"
    />
    <path
      d="m64.4561 33.3133s10.9249-15.3412 35.8969-10.0544c.456 3.0107-.016 5.8697-.016 5.8697s.048.3833.04.9983c-5.3583-2.5396-19.0224-6.9479-35.913 3.1944z"
      fill="#000"
      opacity=".2"
    />
    <path
      className="anim-fill"
      d="m67.7143 31.4683h-6.5166c-1.1776 0-2.1323.9546-2.1323 2.1322v6.5167c0 1.1776.9547 2.1322 2.1323 2.1322h6.5166c1.1777 0 2.1323-.9546 2.1323-2.1322v-6.5167c0-1.1776-.9546-2.1322-2.1323-2.1322z"
      fill="#2259ff"
    />
  </svg>
);
export default LoadingPresent;
