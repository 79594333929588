import MuiLinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { globals } from '../../theme';

export interface NuiLinearProgressProps extends LinearProgressProps {}

const NuiLinearProgress = styled((props: NuiLinearProgressProps) => {
  return <MuiLinearProgress {...props} />;
})<NuiLinearProgressProps>(({ theme }) => ({
  borderRadius: 999,
  '.MuiLinearProgress-bar': {
    borderRadius: 999,
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: globals.colors.white20,
    '.MuiLinearProgress-bar': {
      backgroundColor: globals.colors.white,
    },
  },
  '&.MuiLinearProgress-colorSecondary': {
    backgroundColor: globals.colors.white20,
    '.MuiLinearProgress-bar': {
      backgroundColor: globals.colors.secondaryCyan,
    },
  },
}));

export default NuiLinearProgress;
