import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';

const logEmbedEvents: boolean = true;
const log: boolean =
  logEmbedEvents && process.env.REACT_APP_LOG_DATA === 'true';

const isEmbed = () => {
  const path = window.location.pathname;
  return path.startsWith('/embed') ? true : false;
};

const _getDevice = (): Device => {
  const userAgent = window?.navigator?.userAgent;
  let res: Device = { device: null, type: null, userAgent: null };
  if (userAgent) {
    if (userAgent.startsWith('ios/')) {
      res = { device: 'mobile', type: 'ios', userAgent };
    } else if (userAgent.startsWith('android/')) {
      res = { device: 'mobile', type: 'android', userAgent };
    } else if (userAgent.startsWith('rn/')) {
      res = { device: 'mobile', type: 'rn', userAgent };
    } else {
      res = { device: isMobile ? 'mobile' : 'desktop', type: 'web', userAgent };
    }
  }
  // alert(device.type)
  const embed = isEmbed();
  if (log && embed) console.log(`🔷 device`, res);
  return res;
};

const device = _getDevice();
const getDevice = (): Device => {
  return device;
};

const postMessage = (payload: EmbedMessage): void => {
  const embed = isEmbed();
  if (!embed) return;
  try {
    if (log) console.log(`🔷 message`, payload);
    const { type: deviceType } = device;
    if (deviceType === 'ios') {
      // iOS
      // @ts-ignore
      window.webkit?.messageHandlers?.playlistgptHandler?.postMessage(
        JSON.stringify(payload),
      );
    } else if (deviceType === 'android') {
      // Android
      // @ts-ignore
      window.JSBridge?.showMessageInNative(JSON.stringify(payload));
    } else if (deviceType === 'rn') {
      // React Native
      // @ts-ignore
      window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
    } else {
      // Web
      window.parent?.postMessage(payload, '*');
    }
  } catch (err) {
    console.error(err);
  }
};

const useEmbedAuth = () => {
  const [embedAuth, setEmbedAuth] = useState<AuthTypes>(null);
  const embed = isEmbed();

  const _setEmbedAuth = useCallback((token: string | null | undefined) => {
    if (!token) return;
    setEmbedAuth({
      access_token: token,
      expires_in: 3600,
      refresh_token: '',
    });
  }, []);

  // Post message to get token
  const getEmbedAuth = useCallback(async () => {
    try {
      if (!embed) return;
      const payload = { type: 'token' };
      const { type: deviceType } = device;
      if (deviceType === 'ios') {
        // iOS
        const res: string =
          // @ts-ignore
          await window.webkit?.messageHandlers?.playlistgptHandler?.postMessage(
            JSON.stringify(payload),
          );
        _setEmbedAuth(JSON.parse(res)?.data?.token);
      } else if (deviceType === 'android') {
        // Android
        // @ts-ignore
        const res: string = await window.JSBridge?.postMessage(
          JSON.stringify(payload),
        );
        _setEmbedAuth(JSON.parse(res)?.data?.token);
      } else {
        // Web & React Native
        // Note: RN for testing (we don't have an RN app in production)
        // Listener based system to get the token response...
        const isReactNative = deviceType === 'rn';
        window.addEventListener('message', e => {
          let data = e.data;
          // Parse the JSON if React Native, any errors return null
          if (isReactNative) {
            try {
              data = JSON.parse(e.data);
            } catch (err) {
              data = null;
            }
          }
          if (data?.type === 'token') {
            _setEmbedAuth(data?.data?.token);
          }
        });
        // ...Then post the message
        if (isReactNative) {
          // React Native
          // @ts-ignore
          window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
        } else {
          // Web
          window.parent?.postMessage(payload, '*');
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [embed, _setEmbedAuth]);

  return { embedAuth, getEmbedAuth };
};

export { isEmbed, getDevice, postMessage, useEmbedAuth };
