import axios from 'axios';
import { getCookie } from '../cookieHelper';
import { NapiCookieLabels } from '../../@typings/Napi/NapiCookieLabels.enum';

export const napi = axios.create({
  baseURL: process.env.REACT_APP_NAPI_BASE_URL,
  headers: { 'x-client-id': process.env.REACT_APP_NAPI_CLIENT_ID },
});

export const napiEmbed = axios.create({
  baseURL: process.env.REACT_APP_NAPI_BASE_URL,
  headers: { 'x-client-id': process.env.REACT_APP_NAPI_CLIENT_ID },
});

napi.interceptors.request.use(
  async config => {
    config.headers['Authorization'] = `Bearer ${
      getCookie(NapiCookieLabels.ACCESS_TOKEN) || ''
    }`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
