const LoadingBauble = () => (
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M63.5821 27.0341C60.4627 27.0341 57.9297 24.5612 57.9297 21.5171C57.9297 18.4729 60.4627 16 63.5821 16C66.7014 16 69.2344 18.4729 69.2344 21.5171C69.2344 24.5612 66.7014 27.0341 63.5821 27.0341ZM63.5821 18.2624C61.7105 18.2624 60.1846 19.7282 60.1846 21.5246C60.1846 23.321 61.7105 24.7867 63.5821 24.7867C65.4536 24.7867 66.9795 23.321 66.9795 21.5246C66.9795 19.7282 65.4536 18.2624 63.5821 18.2624Z"
      fill="#2259FF"
    />
    <path
      d="M72.5255 21.5244H55.3579C54.3243 21.5244 53.4863 22.3623 53.4863 23.396V33.5582C53.4863 34.5919 54.3243 35.4298 55.3579 35.4298H72.5255C73.5591 35.4298 74.3971 34.5919 74.3971 33.5582V23.396C74.3971 22.3623 73.5591 21.5244 72.5255 21.5244Z"
      fill="#2259FF"
    />
    <path
      d="M63.9424 112C86.0019 112 103.885 94.1172 103.885 72.0576C103.885 49.998 86.0019 32.1152 63.9424 32.1152C41.8828 32.1152 24 49.998 24 72.0576C24 94.1172 41.8828 112 63.9424 112Z"
      fill="#2259FF"
    />
    <path
      className="anim-fill"
      d="M76.0815 101.094C66.25 106.265 62.2662 109.723 60.6953 111.85C61.7702 111.932 62.845 111.992 63.9424 111.992C64.5362 111.992 65.13 111.97 65.7163 111.947L80.9972 105.025C88.9647 101.154 94.0232 97.4106 97.2327 94.1259C99.4802 90.736 101.224 86.9853 102.359 82.979C97.7363 90.3 94.5193 91.4049 76.0815 101.094Z"
      fill="#FEF5D2"
    />
    <path
      className="anim-fill"
      d="M71.248 44.3442C62.4087 50.2146 56.3279 53.131 51.2242 55.8294C46.1205 58.5278 24.4206 69.1109 25.4578 82.2046C25.4804 82.4601 25.5029 82.7082 25.5255 82.9562C26.4575 86.2409 27.7955 89.3602 29.4867 92.2465C28.5621 89.3827 28.3893 83.9859 36.4168 78.281C48.2026 69.9001 73.4353 57.964 78.2082 53.1911C82.8308 48.5685 85.9802 42.3975 83.575 37.2788C81.8913 36.3317 80.14 35.4974 78.321 34.7983C78.336 36.6248 77.4265 40.2628 71.263 44.3517L71.248 44.3442Z"
      fill="#FEF5D2"
    />
    <path
      className="anim-fill"
      d="M63.9427 32.1152C61.5299 32.1152 59.1772 32.3407 56.8847 32.7466C47.1359 35.8133 37.6201 43.5252 30.7576 49.8315C28.9461 52.5374 27.4503 55.4763 26.3379 58.5957C28.8559 56.4084 36.9887 49.6135 45.8656 45.021C54.7876 40.4059 61.8456 37.6173 64.3485 32.1227C64.2133 32.1227 64.0855 32.1152 63.9502 32.1152H63.9427Z"
      fill="#FEF5D2"
    />
    <path
      className="anim-fill"
      d="M96.3833 48.7642C97.4507 50.5456 100.405 58.3927 80.6965 71.4713C57.6661 86.7523 38.2436 90.2775 42.3401 105.634C44.2192 106.844 46.196 107.904 48.2706 108.79C47.9925 106.866 48.714 103.048 56.1403 98.29C66.4453 91.6906 102.622 74.2148 102.592 61.9179C102.299 60.798 101.961 59.7006 101.57 58.6257C100.307 55.078 98.5481 51.7632 96.3833 48.7642Z"
      fill="#FEF5D2"
    />
  </svg>
);
export default LoadingBauble;
