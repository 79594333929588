import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import { keyframes, styled } from '@mui/material/styles';
import NuiIconButton from '../NuiIconButton/NuiIconButton';
import { globals } from '../../theme';

export interface NuiDialogProps extends DialogProps {}

const MuiDialogAnimIn = keyframes`
0% { transform: scale(0.95); }
100% { transform: scale(1); }
`;

const NuiDialog = styled((props: NuiDialogProps) => {
  return (
    <MuiDialog {...props}>
      <NuiIconButton
        icon="cross"
        onClick={() => props.onClose && props.onClose({}, 'backdropClick')}
        sx={(theme) => ({
          position: 'absolute',
          top: theme.spacing(1),
          right: theme.spacing(1),
          zIndex: 999,
          svg: {
            width: 24,
            height: 24,
            fill: globals.colors.white40,
          },
        })}
      />

      {props.children}
    </MuiDialog>
  );
})<NuiDialogProps>(({ theme }) => ({
  '.MuiModal-backdrop': {
    backgroundColor: globals.colors.black80,
  },
  '.MuiDialog-paper': {
    minWidth: theme.spacing(75),
    maxWidth: theme.spacing(105),
    margin: theme.spacing(4),
    background: globals.colors.bgLight,
    boxShadow: 'none',
    transition: 'none',
    transform: 'scale(0.95)',
    animation: `${MuiDialogAnimIn} 0.3s forwards`,
  },
  '.MuiDialogContent-root': {
    padding: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6),
    },
  },
}));

export default NuiDialog;
