// Useful links:
// Free translator: https://translate.i18next.com
// https://stackoverflow.com/questions/3191664/list-of-all-locales-and-their-short-codes

import da_DK from './locales/da-DK.json';
import de_DE from './locales/de-DE.json';
import el_GR from './locales/el-GR.json';
import en_GB from './locales/en-GB.json';
import en_US from './locales/en-US.json';
import es_ES from './locales/es-ES.json';
import es_LA from './locales/es-LA.json';
import fr_CA from './locales/fr-CA.json';
import fr_FR from './locales/fr-FR.json';
import it_IT from './locales/it-IT.json';
import ja_JP from './locales/ja-JP.json';
import nl_NL from './locales/nl-NL.json';
import pt_BR from './locales/pt-BR.json';
import pt_PT from './locales/pt-PT.json';
import tr_TR from './locales/tr-TR.json';

const getFallbackText = (lang: string) => {
  let text: any = {};
  const langCode: string = lang ? lang.split('-')[0] : '';
  console.log(`Trying fallback for ${langCode}`);
  switch (langCode) {
    case 'da':
      text = da_DK;
      break;
    case 'de':
      text = de_DE;
      break;
    case 'el':
      text = el_GR;
      break;
    case 'en':
      text = en_US;
      break;
    case 'es':
      text = es_ES;
      break;
    case 'fr':
      text = fr_FR;
      break;
    case 'it':
      text = it_IT;
      break;
    case 'ja':
      text = ja_JP;
      break;
    case 'nl':
      text = nl_NL;
      break;
    case 'pt':
      text = pt_PT;
      break;
    case 'tr':
      text = tr_TR;
      break;
    default:
      console.log(`No fallback language: ${lang}, defaulting to en-US`);
      text = en_US;
      break;
  }
  return text;
};

const getText = (lang: string, theme: string = 'default'): any => {
  let text: any = {};
  switch (lang) {
    case 'da-DK':
      text = da_DK;
      break;
    case 'de-DE':
      text = de_DE;
      break;
    case 'el-GR':
      text = el_GR;
      break;
    case 'en-GB':
      text = en_GB;
      break;
    case 'en-US':
      text = en_US;
      break;
    case 'es-ES':
      text = es_ES;
      break;
    case 'es-LA':
      text = es_LA;
      break;
    case 'fr-CA':
      text = fr_CA;
      break;
    case 'fr-FR':
      text = fr_FR;
      break;
    case 'it-IT':
      text = it_IT;
      break;
    case 'ja-JP':
      text = ja_JP;
      break;
    case 'nl-NL':
      text = nl_NL;
      break;
    case 'pt-BR':
      text = pt_BR;
      break;
    case 'pt-PT':
      text = pt_PT;
      break;
    case 'tr-TR':
      text = tr_TR;
      break;
    default:
      text = getFallbackText(lang);
      break;
  }
  let res = { ...text };
  const isDefault = theme === 'default';
  if (!isDefault) {
    const themeText = res._THEMES?.[theme] || {};
    res = { ...res, ...themeText };
  }
  delete res._THEMES;
  return res;
};

export { getText };
