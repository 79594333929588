import MuiButton, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { globals, typography } from '../../theme';

export interface NuiButtonProps extends ButtonProps {}

const NuiButton = styled((props: NuiButtonProps) => {
  return (
    <MuiButton
      variant="contained"
      disableElevation={true}
      disableRipple={true}
      {...props}
    />
  );
})<NuiButtonProps>(({ theme }) => ({
  borderRadius: 999,
  ...typography['body-demi'],
  textTransform: `none`,
  color: globals.colors.white,
  svg: {
    width: 'auto',
    fill: globals.colors.white,
  },
  transform: `scale(1)`,
  filter: `brightness(1)`,
  transition: `transform 0.3s, filter 0.3s`,
  '&:hover': {
    transform: `scale(0.96)`,
    filter: `brightness(0.8)`,
    transition: `transform 0.15s, filter 0.15s`,
  },
  '&.MuiButton-sizeSmall': {
    height: theme.spacing(9),
    padding: `0 ${theme.spacing(5)}`,
    svg: {
      height: theme.spacing(3),
    },
  },
  '&.MuiButton-sizeMedium': {
    height: theme.spacing(11),
    padding: `0 ${theme.spacing(8)}`,
    svg: {
      height: theme.spacing(4),
    },
  },
  '&.MuiButton-sizeLarge': {
    height: theme.spacing(18),
    padding: `0 ${theme.spacing(8)}`,
    svg: {
      height: theme.spacing(6),
    },
  },
  // Contained
  '&.MuiButton-containedPrimary': {
    backgroundColor: globals.colors.primary,
  },
  '&.MuiButton-containedSecondary': {
    backgroundColor: globals.colors.white,
  },
  '&.MuiButton-containedSecondary, &.MuiButton-containedError, &.MuiButton-containedWarning, &.MuiButton-containedSuccess':
    {
      color: globals.colors.bgBase,
      svg: {
        fill: globals.colors.bgBase,
      },
    },
  // Outlined
  '&.MuiButton-outlined': {
    backgroundColor: 'transparent',
  },
  '&.MuiButton-outlinedPrimary': {
    border: `2px solid ${globals.colors.white40}`,
  },
  '&.MuiButton-outlinedSecondary': {
    border: `2px solid ${globals.colors.secondaryCyan}`,
  },
  '&.MuiButton-outlinedError': {
    border: `2px solid ${globals.colors.semanticErrorLight}`,
  },
  '&.MuiButton-outlinedWarning': {
    border: `2px solid ${globals.colors.semanticWarningLight}`,
  },
  '&.MuiButton-outlinedSuccess': {
    border: `2px solid ${globals.colors.semanticSuccessLight}`,
  },
  // Text
  '&.MuiButton-text': {
    backgroundColor: 'transparent',
    color: globals.colors.white80,
  },
}));

export default NuiButton;
