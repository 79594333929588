import { useCallback, useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

const apiKey: string | undefined = process.env.REACT_APP_AMPLITUDE_API_KEY;

const logAnalytics: boolean = false;
const log: boolean = logAnalytics && process.env.REACT_APP_LOG_DATA === 'true';

// Log events
const logEvent = (type: string, data: any) => {
  try {
    if (log) console.log(`📊 ${type}`, data);
    amplitude.track(type, data);
  } catch (err) {
    console.error(err);
  }
};

// Set user (when logged in)
const logUser = (id: string | null) => {
  try {
    if (log) console.log(`📊 user`, { id });
    amplitude.setUserId(id || undefined);
  } catch (err) {
    console.error(err);
  }
};

const useAnalytics = () => {
  const [analytics, setAnalytics] = useState<boolean>(false);
  // Init Amplitude
  const startAnalytics = useCallback(() => {
    if (analytics) return;
    try {
      if (apiKey) {
        amplitude.init(apiKey, { defaultTracking: true });
      } else {
        console.error('Amplitude API key not found');
      }
      setAnalytics(true);
    } catch (err) {
      console.error('Analytics failed to start');
      setAnalytics(true);
    }
  }, [analytics]);
  useEffect(() => {
    if (analytics) return;
    startAnalytics();
  }, [startAnalytics, analytics]);

  // Check for campaign id in url
  useEffect(() => {
    if (!analytics) return;
    const search = window?.location?.search;
    if (!search) return;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    if (id) {
      const source = params.get('source');
      logEvent('campaign', { id, source });
      params.delete('id');
      params.delete('source');
      window.history.pushState({}, document.title, '/');
    }
  }, [analytics]);

  return { analytics };
};

export { useAnalytics, logEvent, logUser };
