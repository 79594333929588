import { Helmet, HelmetProvider } from 'react-helmet-async';
import MuiThemeProvider, {
  ThemeProviderProps,
} from '@mui/system/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from '../../theme';

export interface NuiThemeProviderProps extends ThemeProviderProps {}

const NuiThemeProvider = ({ children, ...props }: NuiThemeProviderProps) => {
  return (
    <HelmetProvider>
      {/* Fonts from Napster CDN */}
      <Helmet>
        <link rel="preconnect" href="https://static.napster.com" />
        <link
          href="https://static.napster.com/fonts/fonts.css"
          rel="stylesheet"
        />
      </Helmet>
      {/* Preload MUI theme */}
      <MuiThemeProvider {...props} theme={theme}>
        {/* Reset CSS */}
        <CssBaseline />
        {/* Add children */}
        {children}
      </MuiThemeProvider>
    </HelmetProvider>
  );
};

export default NuiThemeProvider;
