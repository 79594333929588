import { napi, napiEmbed } from '../helpers/configs/axios';
import { transformImageToFormData } from '../helpers/imageHelper';

export const createUserPlaylist = async ({
  playlistName,
  trackIds,
  description,
  authOverride,
}: CreatePlaylistPayload) => {
  const res = authOverride
    ? await napiEmbed.post<CreatePlaylistResponse>(
        '/me/library/playlists',
        {
          playlistName,
          trackIds,
          description,
          privacy: 'private',
        },
        {
          headers: {
            Authorization: `Bearer ${authOverride.access_token}`,
          },
        },
      )
    : await napi.post<CreatePlaylistResponse>('/me/library/playlists', {
        playlistName,
        trackIds,
        description,
        privacy: 'private',
      });

  return res.data;
};

export const postUserPlaylistArtwork = async ({
  playlistId,
  imageUrlOrBase64,
  authOverride,
}: PostPlaylistPayload) => {
  const formData = await transformImageToFormData(
    playlistId,
    imageUrlOrBase64,
    'displayImage',
  );
  const res = authOverride
    ? await napiEmbed.post(
        `/me/library/playlists/${playlistId}/images`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authOverride.access_token}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
    : await napi.post(`/me/library/playlists/${playlistId}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  const status = res?.status;
  if (status !== 201) {
    console.error('Artwork not added');
    return { ok: false };
  }
  return { ok: true };
};
