import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NuiThemeProvider from './components/NuiThemeProvider';

import HomeScreen from './screens/Home';

import LocaleProvider from './components/LocaleProvider';
import { useAnalytics } from './helpers/analyticsHelper';
import { theme } from './theme';

function App() {
  const { analytics } = useAnalytics();
  return (
    <div className="App">
      {/* Theme */}
      <NuiThemeProvider theme={theme}>
        {/* Analytics */}
        {analytics && (
          <BrowserRouter>
            {/* Localised text context provider */}
            <LocaleProvider>
              {/* Router for navigation */}
              <Routes>
                {/* embed version for streaming apps */}
                <Route path="/embed" element={<HomeScreen />} />
                {/* Root */}
                <Route path="/" element={<HomeScreen />} />
              </Routes>
            </LocaleProvider>
          </BrowserRouter>
        )}
      </NuiThemeProvider>
    </div>
  );
}

export default App;
