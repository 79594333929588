import {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { getText } from '../../helpers/textHelper';
import { getAppTheme } from '../../helpers/themeHelper';

const log: boolean = false;

const initialStates: {
  locale: string;
  text: any;
  onLocale: Function;
} = {
  locale: 'en-US',
  text: getText('en-US'),
  onLocale: () => {},
};

const LocaleContext = createContext(initialStates);

const LocaleProvider: React.FC<{ children: any }> = ({ children }) => {
  const [locale, setLocale] = useState(initialStates.locale);
  const [text, setText] = useState(initialStates.text);
  let [searchParams] = useSearchParams();

  // Update locale using user profile, or default to browser locale
  const onLocale = useCallback(
    async (reqLocale: null | string) => {
      // Ignore if the same
      if (reqLocale === locale || (!reqLocale && navigator.language === locale))
        return;
      let newLocale = reqLocale || null;
      if (log && locale) console.log(`🌐 Requested locale: ${newLocale}`);
      // If no localeuage, use browser localeuage
      if (!newLocale) {
        newLocale = navigator.language;
        if (log) console.log(`🌐 Reverting to browser locale: ${newLocale}`);
      }
      // If localeuage isn't available, revert to default
      if (!newLocale) {
        newLocale = 'en';
        if (log) console.log(`🌐 Using locale fallback: ${newLocale}`);
      }
      if (log) console.log(newLocale);
      setLocale(newLocale);
    },
    [locale],
  );

  // Update text on localeuage change
  useEffect(() => {
    const theme = getAppTheme();
    const newText = getText(locale, theme);
    setText(newText);
  }, [locale, searchParams]);

  return (
    <LocaleContext.Provider value={{ locale, text, onLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

const useLocale = () => useContext(LocaleContext);

export { LocaleProvider as default, useLocale };
