import { Box, keyframes, useMediaQuery, useTheme } from '@mui/material';
import { globals } from '../../theme';

const animIce = keyframes`
  0% {
    opacity: 0;
    transform: scaleY(0.5);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
`;

const antlerRotate: number = 20;
const antlerGap: number = 25;
const animAntler = (left: boolean) => keyframes`
  0% {
    opacity: 0;
    transform: rotate(${
      left ? antlerRotate : -antlerRotate
    }deg) scale(0.8) translateX(${left ? -antlerGap : antlerGap}%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1) translateX(${
      left ? -antlerGap : antlerGap
    }%);
  }
`;

const animNose = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  75% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Ice: React.FC<{ sx?: any }> = ({ sx }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: -10,
        width: '100%',
        opacity: 0,
        animation: `${animIce} 4s 3s forwards`,
        transformOrigin: 'top',
        ...sx,
      }}>
      <svg
        viewBox="0 0 343 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: '100%', width: 'auto' }}>
        <path
          d="M342.2,10.6h0c0,5-4.7,19.8-7.2,18.1-2.6-1.7-4.8-3.5-7.4-4.4-2.6-.9-5.1-.9-7.7-1.3-2.6-.4-5.1-1.3-7.7-.9-2.6.4-4.8,2.2-7.4,3-2.6.9-5.1.9-7.7-1.3-2.6-2.2-5.1-6.5-7.7-7.4-2.6-.9-4.8,1.7-7.4,3.5-2.6,1.7-5.1,2.6-7.7,4.4-2.6,1.7-5.1,4.4-7.7,3.9-2.6-.4-4.8-3.9-7.4-6.1-2.6-2.2-5.1-3-7.7-3s-5.1.9-7.7,3.5c-2.6,2.6-4.8-2.1-7.4.6-2.6,2.6-5.1,3.5-7.7,3-2.6-.4-5.1-2.2-7.7-2.6-2.6-.4-4.8.4-7.4,0-2.6-.4-5.1-2.2-7.7-2.6-2.6-.4-5.1.4-7.7-.4-2.6-.9-4.8,5.5-7.4,4.7-2.6-.9-5.1,0-7.7,1.7-2.6,1.7-5.1,4.3-7.7,6.1-2.6,1.7-4.8,2.6-7.4-.9-2.6-3.5-5.1-11.3-7.7-12.2-2.6-.9-5.1,5.2-7.7,8.7-2.6,3.5-4.8,4.4-7.4,3-2.6-1.3-5.1-4.8-7.7-8.7-2.6-3.9-5.1-8.3-7.7-8.7-2.6-.4-4.8,3-7.4,3.5-2.6.4-5.1-2.2-7.7-2.6-2.6-.4-5.1,1.3-7.7,3.5-2.6,2.2-4.8,4.8-7.4,7.4-2.6,2.6-5.1,5.2-7.7,4.4-2.6-.9-5.1-5.2-7.7-7.8-2.6-2.6-4.8-3.5-7.4-3-2.6.4-5.1,2.2-7.7,1.7-2.6-.4-5.1-3-7.7-4.3-2.6-1.3-4.8-1.3-7.4-.9s-5.1,1.3-7.7,1.7c-2.6.4-5.1.4-7.7.9-2.6.4-4.8,1.3-7.4,3.9-2.6,2.6-5.1,6.9-7.7,5.6-2.6-1.3-5.1-8.3-7.7-12.2-2.6-3.9-4.8-4.8-7.4-5.2-2.6-.4-5.1-.4-6.5-.4h340.1Z"
          fill={globals.colors.white}
          fillOpacity="0.4"
        />
        <path
          d="M334.4,19.5c-2.6,2.4-5.4,3.2-8,3.6-2.8.4-5.4.4-8.3-1.2-2.6-1.6-5.4-4.8-8-3.6-2.8,1.2-5.4,6.8-8.3,9.9-2.6,3.2-5.4,4-8,2-2.8-2-5.4-6.8-8.3-7.6-2.8-.8-5.4,2.4-8.3,1.6-2.6-.8-5.4-5.6-8-8.4-2.8-2.8-5.4-3.6-8.3-2.4-2.6,1.2-5.4,4.4-8,5.2-2.8.8-5.4-.8-8.3-2-2.6-1.2-5.4-2-8-2.8-2.8-.8-5.4-1.6-8.3-2-2.8-.4-5.4-.4-8.3-.4s-5.4,0-8,.4c-2.8.4-5.4,1.2-8.3,1.2s-5.4-.8-8-.4c-2.8.4-5.4,2-8.3,3.6-2.6,1.6-5.4,3.2-8,3.6-2.8.4-5.4-.4-8.3,1.2s-5.4,5.6-8.3,4.8c-2.6-.8-5.4-6.4-8-7.5-2.8-1.2-5.4,2-8.3,1.6-2.6-.4-5.4-4.4-8-4-2.8.4-5.4,5.2-8.3,8.7-2.6,3.6-5.4,6-8,4-2.8-2-5.4-8.4-8.3-8.7-2.8-.4-5.4,5.2-8.3,6-2.6.8-5.4-3.2-8-3.2s-5.4,4-8.3,2.8c-2.6-1.2-5.4-7.6-8-9.9-2.8-2.4-5.4-.8-8.3-.4-2.6.4-5.4-.4-8-.4s-5.4.8-8.3,2.4-5.4,4-8.3,3.6c-2.6-.4-5.4-3.6-8-5.2-2.8-1.6-5.4-1.6-8.3-.4-2.6,1.2-5.4,3.6-8,5.6-2.8,2-5.4,3.6-8.3,2.8-2.6-.8-5.4-4-8-4.8-2.8-.8-7.2-4.7-7.3-6l-1-3.5h73.2c2.8,0-5.1,0,4.1-1.9,7.1-1.2,6.4-.7,9-.7h11.4c2.6,0,5.4,2.7,8,2.7h65.2c2.8,0,2.3-1.6,5.1-1.6s12-1.1,14.5-1.1,6.7-.5,9,1.1c2.6,0,1.3,1.6,3.8,1.6s9.7-1.9,12.5-1.9,5.2-.7,10.3-.7,7.1.7,10,.7,2.6,1.2,5.4,1.2,0,.8,2.6.8h89.4c2.8,0,6.4,1.4,7.8,1.4"
          fill={globals.colors.white}
          fillOpacity="0.8"
        />
        <path
          d="M309.5,13.3c-5.9,1-15.1,4.5-21,4.5s-12.2-7.2-18.2-7.2-17,3.7-23,3.7-12.9-3.1-18.8-3.1-17.8,2.2-23.8,2.2-9.8-2.2-15.7-2.2-20.3,4.5-26.3,4.5-11.9-5-17.9-5-9.9,10-15.9,10-2.4-10-8.4-10-5.5,8.8-11.5,8.8-11.9-8.3-17.9-8.3-11.9-.5-17.9-.5-11.9,3.7-18.2,3.7-11.9-3.7-17.9-3.7-16.2,5-22.2,5c0,0-11.7-1.7-14.1-6.4h0c-2-3.2,12.4-3.8,18.3-3.8s11.9.8,17.9.2c6-.6,11.9-2.6,17.9-3.2,6.2-.6,12.2.2,18.2.2s11.9-.8,17.9-1c6-.2,11.9.2,17.9,0,6-.2,11.9-1,17.9-.2,6,.8,11.9,3.2,17.9,3.8s11.9-.6,17.9-1.8c6-1.2,12.2-2.4,18.2-3,6-.6,11.9-.6,17.9,0,6,.6,11.9,1.8,17.9,2,6,.2,11.9-.6,17.9.2,6,.8,11.9,3.2,17.9,3.6,6,.4,11.9-1.2,17.9-1.8,6-.6,11.9-.2,18.2.2,6,.4,11.9.8,17.9-.2,6-1,11.9,2.6,17.9,1.4,6-1.2,18.1-6.7,17.9,4.7,0,0,.3,4.6-3.3,1,0,0-4-2.4-7.1.6,0,0-1.7,1.6-5.5,1.7s-8.3-2-16.7-.6Z"
          fill={globals.colors.white}
        />
      </svg>
    </Box>
  );
};

const Antlers: React.FC = () => {
  const theme = useTheme();
  const isVariable = useMediaQuery('(max-width:400px)');
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        position: 'absolute',
        top: isLg ? '-50%' : isVariable ? `-40%` : '-45%',
        width: '100%',
        svg: {
          position: 'absolute',
          width: isLg ? '30%' : '25%',
          height: 'auto',
          opacity: 0,
          '&.left': {
            right: '50%',
            animation: `${animAntler(true)} 0.6s 0.6s forwards`,
            transformOrigin: 'bottom right',
          },
          '&.right': {
            left: '50%',
            animation: `${animAntler(false)} 0.6s 0.6s forwards`,
            transformOrigin: 'bottom left',
          },
        },
      }}>
      {/* Left */}
      <svg
        className="left"
        viewBox="0 0 178 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M175.308 122.229C175.308 122.229 154.397 98.8535 154.397 84.8332C154.397 70.8128 152.387 61.8168 152.387 52.6653C152.387 46.1576 152.531 42.7841 148.332 41.4682C144.145 40.1403 142.326 42.485 143.451 51.804C144.575 61.135 142.733 57.4863 142.733 57.4863L131.907 48.6698C131.907 48.6698 120.973 41.3246 118.126 45.8346C114.178 52.0911 119.992 54.7708 128.486 61.9125C136.979 69.0543 146.872 77.7752 139.719 87.2497C132.565 96.7242 108.711 81.4956 101.522 76.1961C94.3199 70.8846 85.7666 57.4624 96.4971 32.3645C107.228 7.26665 96.1861 8.9534 91.4728 13.6667C86.7595 18.3801 75.5264 26.8736 79.6057 65.7406C80.5628 74.8682 81.2566 70.3224 71.9615 63.5275C62.6665 56.7327 59.7595 51.8877 59.7595 21.6698C59.7595 -8.54812 44.2199 1.99107 43.227 14.0017L41.4086 38.3938C41.4086 38.3938 30.9891 33.8599 23.9191 29.1465C16.8491 24.4332 11.2505 25.773 6.42953 30.1514C1.60854 34.5298 -5.1863 42.0902 20.4977 51.0623C46.1937 60.0224 42.1862 61.1469 59.1016 76.3994C76.0049 91.652 82.2973 89.1637 115.996 100.325C149.708 111.486 150.593 116.475 161.838 126.464"
          fill="#8E4F0B"
        />
        <path
          d="M175.308 122.229C175.308 122.229 154.397 98.8535 154.397 84.8332C154.397 70.8128 152.387 61.8168 152.387 52.6653C152.387 46.1576 152.531 42.7841 148.332 41.4682C144.145 40.1403 142.326 42.485 143.451 51.804C144.575 61.135 142.733 57.4863 142.733 57.4863L131.907 48.6698C131.907 48.6698 120.973 41.3246 118.126 45.8346C114.178 52.0911 119.992 54.7708 128.486 61.9125C136.979 69.0543 146.872 77.7752 139.719 87.2497C132.565 96.7242 108.711 81.4956 101.522 76.1961C94.3199 70.8846 85.7666 57.4624 96.4971 32.3645C107.228 7.26665 96.1861 8.9534 91.4728 13.6667C86.7595 18.3801 75.5264 26.8736 79.6057 65.7406C80.5628 74.8682 81.2566 70.3224 71.9615 63.5275C62.6665 56.7327 59.7595 51.8877 59.7595 21.6698C59.7595 -8.54812 44.2199 1.99107 43.227 14.0017L41.4086 38.3938C41.4086 38.3938 30.9891 33.8599 23.9191 29.1465C16.8491 24.4332 11.2505 25.773 6.42953 30.1514C1.60854 34.5298 -5.1863 42.0902 20.4977 51.0623C46.1937 60.0224 42.1862 61.1469 59.1016 76.3994C76.0049 91.652 82.2973 89.1637 115.996 100.325C149.707 111.486 150.593 116.475 161.838 126.464"
          stroke={globals.colors.black}
          strokeWidth={4}
          strokeMiterlimit={10}
        />
      </svg>
      {/* Right */}
      <svg
        className="right"
        viewBox="0 0 178 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 122.247C2 122.247 22.9109 98.8716 22.9109 84.8513C22.9109 70.8309 24.9207 61.8349 24.9207 52.6834C24.9207 46.1756 24.7771 42.8021 28.976 41.4862C33.163 40.1584 34.9813 42.5031 33.8568 51.8221C32.7323 61.153 34.5746 57.5044 34.5746 57.5044L45.4009 48.6878C45.4009 48.6878 56.3349 41.3427 59.182 45.8527C63.1297 52.1092 57.3158 54.7888 48.8222 61.9306C40.3287 69.0724 30.4355 77.7932 37.5892 87.2677C44.7429 96.7422 68.5967 81.5136 75.7863 76.2141C82.9879 70.9027 91.5413 57.4805 80.8107 32.3826C70.0801 7.28472 81.1217 8.97147 85.835 13.6848C90.5483 18.3981 101.781 26.8917 97.7021 65.7587C96.7451 74.8863 96.0512 70.3404 105.346 63.5456C114.641 56.7507 117.548 51.9058 117.548 21.6879C117.548 -8.53005 133.088 2.00914 134.081 14.0198L135.899 38.4118C135.899 38.4118 146.319 33.8779 153.389 29.1646C160.459 24.4513 166.057 25.7911 170.878 30.1695C175.699 34.5478 182.494 42.1083 156.81 51.0804C131.114 60.0405 135.122 61.165 118.206 76.4175C101.303 91.67 95.0105 89.1818 61.3114 100.343C27.6003 111.504 26.7151 116.493 15.4701 126.482"
          fill="#8E4F0B"
        />
        <path
          d="M2 122.247C2 122.247 22.9109 98.8716 22.9109 84.8513C22.9109 70.8309 24.9207 61.8349 24.9207 52.6834C24.9207 46.1756 24.7771 42.8021 28.976 41.4862C33.163 40.1584 34.9813 42.5031 33.8568 51.8221C32.7323 61.153 34.5746 57.5044 34.5746 57.5044L45.4009 48.6878C45.4009 48.6878 56.3349 41.3427 59.182 45.8527C63.1297 52.1092 57.3158 54.7888 48.8222 61.9306C40.3287 69.0724 30.4355 77.7932 37.5892 87.2677C44.7429 96.7422 68.5967 81.5136 75.7863 76.2141C82.9879 70.9027 91.5413 57.4805 80.8107 32.3826C70.0801 7.28472 81.1217 8.97147 85.835 13.6848C90.5483 18.3981 101.781 26.8917 97.7021 65.7587C96.7451 74.8863 96.0512 70.3404 105.346 63.5456C114.641 56.7507 117.548 51.9058 117.548 21.6879C117.548 -8.53005 133.088 2.00914 134.081 14.0198L135.899 38.4118C135.899 38.4118 146.319 33.8779 153.389 29.1646C160.459 24.4513 166.057 25.7911 170.878 30.1695C175.699 34.5478 182.494 42.1083 156.81 51.0804C131.114 60.0405 135.122 61.165 118.206 76.4175C101.303 91.67 95.0105 89.1818 61.3114 100.343C27.6003 111.504 26.7151 116.493 15.4701 126.482"
          stroke={globals.colors.black}
          strokeWidth={4}
          strokeMiterlimit={10}
        />
      </svg>
    </Box>
  );
};

const Nose: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '28%',
        left: '50%',
        width: '20%',
        transform: 'translateX(-50%)',
        svg: {
          width: '100%',
          height: 'auto',
          opacity: 0,
          animation: `${animNose} 0.6s 1s forwards`,
        },
      }}>
      <svg
        className="nose"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.2649 14.9582C10.2649 14.9582 3.40726 15.8583 1.27139 9.65068C-0.328718 4.99321 1.19282 0 10.3649 0C19.537 0 19.2869 4.62889 19.2869 6.68618C19.2869 8.74347 18.2512 14.3081 10.2649 14.9582Z"
          fill="#FF292D"
        />
        <path
          d="M10.1002 5.38599C10.1002 5.38599 7.57149 5.56457 6.78572 4.3502C6.19282 3.43585 6.75715 2.46436 10.136 2.46436C13.5148 2.46436 13.4219 3.37156 13.4219 3.77159C13.4219 4.17162 13.0433 5.26455 10.1002 5.39313V5.38599Z"
          fill={globals.colors.white80}
        />
      </svg>
    </Box>
  );
};

export { Ice, Antlers, Nose };
