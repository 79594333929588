import { Box, keyframes } from '@mui/material';
import { globals } from '../../theme';
import { isEmbed } from '../../helpers/embedHelper';
import { useAppThemed } from '../../helpers/themeHelper';

const animLabsCard = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  75% {
    opacity: 1;
    transform: scale(1.01);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

interface LabsCardProps {
  state: string;
  children?: React.ReactNode;
}

const LabsCard: React.FC<LabsCardProps> = ({ state, children }) => {
  const embed = isEmbed();
  const isPlaylist =
    state === 'playlist' || state === 'saving' || state === 'saved';
  const styles = useAppThemed({
    default: {
      background: globals.colors.black,
    },
    holidays: {
      background: `linear-gradient(#013526, #010A17)`,
    },
  });
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        maxWidth: 420,
        maxHeight: embed ? null : '75vh',
        minHeight: isPlaylist ? 480 : 0,
        display: 'flex',
        flexDirection: 'column',
        border: embed ? null : `${globals.colors.white} 2px solid`,
        background: embed ? null : styles.background,
        borderRadius: embed ? 0 : 2,
        boxShadow: embed ? null : `0px 4px 32px 0px ${globals.colors.black80}`,
        opacity: 0,
        animation: `${animLabsCard} 0.3s 0.3s forwards`,
        [theme.breakpoints.up('lg')]: {
          maxHeight: '85vh',
        },
      })}>
      {children}
    </Box>
  );
};

export default LabsCard;
