import { Box } from '@mui/material';
import { useLocale } from '../LocaleProvider';
import NuiIcon from '../NuiIcon';
import NuiButton from '../NuiButton';
import NuiIconButton from '../NuiIconButton';
import NuiCircularProgress from '../NuiCircularProgress';
import { isEmbed } from '../../helpers/embedHelper';
import { useAppThemeColors, useAppThemed } from '../../helpers/themeHelper';
import { globals } from '../../theme';

interface LabsCardFooterProps {
  state: string;
  loading: boolean;
  artwork: {
    loading: boolean;
    data?: ArtworkTypes | null;
  };
  playing: boolean;
  onAction: Function;
}

const LabsCardFooter: React.FC<LabsCardFooterProps> = ({
  state,
  loading,
  artwork,
  playing,
  onAction,
}) => {
  const embed = isEmbed();
  const isActive =
    state === 'playlist' || state === 'saving' || state === 'saved';
  const isSaved = state === 'saved';
  const isLoading = loading || artwork?.loading;
  const text = useLocale().text;
  const themeColors = useAppThemeColors();
  const styles = useAppThemed({
    default: {
      buttonSaved: {
        '&.MuiButton-containedPrimary': {
          backgroundColor: themeColors.tertiary,
          color: globals.colors.black,
          svg: {
            fill: globals.colors.black,
          },
        },
      },
    },
    holidays: {
      buttonSaved: {
        '&.MuiButton-containedPrimary': {
          backgroundColor: themeColors.primary,
          color: globals.colors.black,
          svg: {
            fill: globals.colors.black,
          },
        },
      },
    },
  });

  if (!isActive) return null;
  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        width: '100%',
        padding: embed
          ? `${theme.spacing(4)} ${theme.spacing(2.5)} ${theme.spacing(10)}`
          : `${theme.spacing(4)} ${theme.spacing(2.5)}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        background: embed ? globals.colors.bgBase : null,
        borderTop: embed ? null : `${globals.colors.white} 2px solid`,
        boxShadow: embed ? `0px -8px 8px 0px ${globals.colors.black20}` : null,
      })}>
      {/* Rate */}
      <NuiIconButton
        icon="arrow-left"
        sx={{
          '&.MuiIconButton-root.MuiIconButton-sizeMedium svg': {
            width: 24,
            height: 24,
          },
        }}
        onClick={() => onAction('back')}
      />

      {/* CTA */}
      <Box
        sx={(theme) => ({
          flex: 1,
          height: theme.spacing(11),
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        })}>
        {isLoading ? (
          <NuiCircularProgress
            size={32}
            sx={{
              '&.MuiCircularProgress-colorPrimary': {
                color: themeColors.primary,
              },
            }}
          />
        ) : (
          <NuiButton
            disabled={isLoading}
            startIcon={isSaved ? <NuiIcon name="tick-circle-active" /> : null}
            sx={isSaved ? styles.buttonSaved : null}
            onClick={() => onAction('save')}>
            {isSaved ? text.CTA_VIEW : text.CTA_SAVE}
          </NuiButton>
        )}
      </Box>

      {/* Right */}
      <NuiIconButton
        icon={playing ? 'stop-circle-filled' : 'play-circle-filled'}
        sx={{
          '&.MuiIconButton-root.MuiIconButton-sizeMedium svg': {
            width: 24,
            height: 24,
            fill: themeColors.primary,
          },
        }}
        onClick={() => onAction(playing ? 'pause' : 'play')}
      />
    </Box>
  );
};

export default LabsCardFooter;
