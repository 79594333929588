import React from 'react';
import svgs from './svgs';
import { globals } from '../../theme';

export interface NuiIconProps {
  name: string;
  className?: string;
  size?: number;
  color?: string;
  sx?: React.CSSProperties;
}

const NuiIcon = ({
  name = 'heart',
  className,
  size,
  color,
  sx,
  ...props
}: NuiIconProps) => {
  // @ts-ignore
  const Comp = svgs[name] || svgs._missing;
  return (
    <svg
      className={className}
      width={size && globals.unitsPx(size)}
      height={size && globals.unitsPx(size)}
      viewBox="0 0 24 24"
      style={{
        width: size && globals.unitsPx(size),
        height: size && globals.unitsPx(size),
        fill: color,
        ...sx,
      }}
      {...props}>
      <Comp />
    </svg>
  );
};

export default NuiIcon;
