import { Link, LinkProps } from '@mui/material';

export interface NuiLinkProps extends LinkProps {
  variant?: any;
}

const NuiLink = ({ variant = 'body-demi', ...props }: NuiLinkProps) => {
  return <Link variant={variant} {...props} />;
};

export default NuiLink;
