import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { globals } from '../../theme';
import NuiIcon from '../NuiIcon/';

export interface NuiIconButtonProps extends IconButtonProps {
  variant?: string;
  icon?: string;
}

const _getVariantStyles = (variant?: string, color?: string): any => {
  if (!variant) return null;
  let colors: any = [globals.colors.primary, globals.colors.white];
  switch (color) {
    case 'primary':
      colors = [globals.colors.primary, globals.colors.white];
      break;
    case 'secondary':
      colors = [globals.colors.white, globals.colors.bgBase];
      break;
    case 'error':
      colors = [globals.colors.semanticErrorLight, globals.colors.bgBase];
      break;
    case 'warning':
      colors = [globals.colors.semanticWarningLight, globals.colors.bgBase];
      break;
    case 'success':
      colors = [globals.colors.semanticSuccessLight, globals.colors.bgBase];
      break;
    default:
      break;
  }
  let styles: any = {
    backgroundColor: 'transparent',
    border: 'none',
    color: globals.colors.white80,
  };
  switch (variant) {
    case 'contained':
      styles = { backgroundColor: colors[0], color: colors[1], border: 'none' };
      break;
    case 'outlined':
      styles = {
        backgroundColor: 'transparent',
        color: globals.colors.white80,
        border: `2px solid ${colors[0]}`,
      };
      break;
    default:
      break;
  }
  return styles;
};

const NuiIconButton = styled(
  ({ icon = 'plus', variant, color, ...props }: NuiIconButtonProps) => {
    const styles = _getVariantStyles(variant, color);
    return (
      <MuiIconButton disableRipple disableFocusRipple sx={styles} {...props}>
        {icon && <NuiIcon name={icon} color={styles?.color} />}
      </MuiIconButton>
    );
  },
)<NuiIconButtonProps>(({ theme }) => ({
  transform: `scale(1)`,
  filter: `brightness(1)`,
  transition: `transform 0.3s, filter 0.3s`,
  padding: 0,
  '&:hover': {
    transform: `scale(0.96)`,
    filter: `brightness(0.8)`,
    transition: `transform 0.15s, filter 0.15s`,
  },
  svg: {
    width: 'auto',
    fill: globals.colors.white,
  },
  '&.MuiIconButton-sizeSmall': {
    width: theme.spacing(9),
    height: theme.spacing(9),
    svg: {
      height: theme.spacing(3),
    },
  },
  '&.MuiIconButton-sizeMedium': {
    width: theme.spacing(11),
    height: theme.spacing(11),
    svg: {
      height: theme.spacing(4),
    },
  },
  '&.MuiIconButton-sizeLarge': {
    width: theme.spacing(18),
    height: theme.spacing(18),
    svg: {
      height: theme.spacing(6),
      width: 'auto',
    },
  },
}));

export default NuiIconButton;
