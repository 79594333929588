import { createUserPlaylist, postUserPlaylistArtwork } from '../services/napi';

const createPlaylist = async (
  data: {
    title?: string;
    description?: string;
    tracks?: Array<any>;
  },
  artworkExport?: string | null,
  authOverride?: NapiToken | null,
): Promise<{
  ok: boolean;
  id?: string;
  url?: string;
  error?: string;
}> => {
  const { title, description, tracks } = data;
  const trackIds = tracks?.filter(t => t.found).map(track => track.id) || [];

  if (!title || !tracks?.length) {
    return { ok: false, error: 'Missing playlist info' };
  }

  try {
    // Create Playlist
    const res = await createUserPlaylist({
      playlistName: title,
      trackIds,
      description,
      authOverride,
    });
    const id = res?.id;
    if (!id) throw new Error('Playlist not created');

    // Add artwork
    // Not a deal breaker if this part fails, so don't handle !ok response
    if (artworkExport) {
      try {
        await postUserPlaylistArtwork({
          playlistId: id,
          imageUrlOrBase64: artworkExport,
          authOverride,
        });
      } catch (e) {
        console.error('Failed to add artwork');
      }
    }

    const url = `https://play.napster.com/playlist/${res.id}`;
    return { ok: true, id, url };
  } catch (e: any) {
    return { ok: false, error: e.message };
  }
};

export { createPlaylist };
