import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { globals } from '../../theme';

const nodeConfig = {
  amount: 1000,
  size: [1, 3],
  swing: [0.1, 1],
  speed: [40, 100],
  amplitude: [25, 50],
};

const _rand = (min: number, max: number): number =>
  Math.random() * (max - min) + min;

class Node {
  origin: [number, number];
  position: [number, number];
  velocity: [number, number];
  size: number;
  amplitude: number;
  dx: number;
  constructor(
    origin: [number, number],
    velocity: [number, number] = [0, 0],
    size: number,
    amplitude: number,
  ) {
    this.origin = origin;
    this.position = [...origin];
    this.velocity = velocity;
    this.size = size;
    this.amplitude = amplitude;
    this.dx = Math.random() * 100;
  }
  update(deltaTime: number) {
    this.position[1] += this.velocity[1] * deltaTime;
    this.dx += this.velocity[0] * deltaTime;
    this.position[0] = this.origin[0] + this.amplitude * Math.sin(this.dx);
  }
}

const Snow: React.FC<{ embed: boolean }> = ({ embed }) => {
  const [active, setActive] = useState(true);
  const canvasRef = useRef<any>(null);
  const nodesRef = useRef<any>([]);
  const timeRef = useRef<number>(0);
  const timeoutRef = useRef<any>(null);
  let opacity: number = 0;
  if (active) {
    opacity = embed ? 0.4 : 1;
  }

  const nodesStart = useCallback(() => {
    if (!active || !canvasRef.current) return;
    const nodes = [];
    for (let i = 0; i < nodeConfig.amount; i++) {
      const origin: [number, number] = [
        _rand(0, canvasRef.current.width),
        _rand(-canvasRef.current.height, 0),
      ];
      const velocity: [number, number] = [
        _rand(nodeConfig.swing[0], nodeConfig.swing[1]),
        _rand(nodeConfig.speed[0], nodeConfig.speed[1]),
      ];
      const size = _rand(nodeConfig.size[0], nodeConfig.size[1]);
      const amplitude = _rand(nodeConfig.amplitude[0], nodeConfig.amplitude[1]);
      nodes.push(new Node(origin, velocity, size, amplitude));
    }
    nodesRef.current = nodes;
  }, [active]);

  const render = useCallback(() => {
    if (!active || !canvasRef.current) return;
    const nowTime = performance.now();
    const deltaTime = (nowTime - timeRef.current) / 1000;
    timeRef.current = nowTime;
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext('2d');
    // Clear
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // Update nodes
    nodesRef.current.forEach((node: any) => {
      node.update(deltaTime);
      if (node.position[1] - node.size > canvas.height) {
        node.position[1] = -node.size;
        node.position[0] = node.origin[0] = Math.random() * canvas.width;
        node.dx = Math.random() * 100;
      }
    });
    // Render
    ctx.fillStyle = globals.colors.white;
    nodesRef.current.forEach((node: any) => {
      ctx.fillRect(node.position[0], node.position[1], node.size, node.size);
    });
    // Request new frame
    requestAnimationFrame(render);
  }, [active]);

  const onReset = useCallback(() => {
    if (!canvasRef.current) return;
    setActive(false);
    // Debounce requests
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      nodesStart();
      setActive(true);
      clearTimeout(timeoutRef.current);
    }, 1000);
  }, [nodesStart]);

  const onVisibilityChange = useCallback(() => {
    if (document.hidden) return;
    onReset();
  }, [onReset]);

  // Start once
  useEffect(() => {
    onReset();
    timeRef.current = performance.now();
    requestAnimationFrame(render);
    // Handle resize and focussing / blurring of window
    window.addEventListener('resize', onReset);
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('resize', onReset);
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        pointerEvents: 'none',
        userSelect: 'none',
        opacity,
        transition: 'opacity 0.3s',
      }}>
      <canvas
        ref={canvasRef}
        id="node_canvas"
        style={{ position: 'absolute', width: '100%', height: '100%' }}
      />
    </Box>
  );
};

export default Snow;
