import { Helmet } from 'react-helmet-async';
import { useLocale } from '../LocaleProvider';

const Meta: React.FC<{ data: PlaylistTypes }> = ({ data }) => {
  const text = useLocale().text;
  const metaText = text?.META;
  if (!metaText) return null;
  const { AUTHOR, TITLE, DESCRIPTION, URL, CREATOR_TWITTER } = metaText;
  const title = data?.title || TITLE;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={DESCRIPTION} />
      <meta
        name="keywords"
        content="AI, Playlists, Music, Tracks, Generative"
      />
      {/* Generic / Facebook */}
      <meta name="author" content={AUTHOR} />
      <meta property="og:title" content={TITLE} />
      <meta property="og:url" content={URL} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={DESCRIPTION} />
      <meta
        property="og:image"
        content="https://playlistgpt.napster.com/img/social.png"
      />
      {/* Twitter */}
      <meta name="twitter:creator" content={CREATOR_TWITTER} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="twitter:site" content={CREATOR_TWITTER} />
    </Helmet>
  );
};

export default Meta;
