import { useState } from 'react';
import { DialogContent, Stack } from '@mui/material';

import { useLocale } from '../LocaleProvider';
import NuiDialog from '../NuiDialog';
import NuiTypography from '../NuiTypography';
import NuiLink from '../NuiLink';
import NuiButton from '../NuiButton';
import NuiIcon from '../NuiIcon';
import NuiTextField from '../NuiTextField';
import NuiCircularProgress from '../NuiCircularProgress';
import { globals } from '../../theme';

const id = 'connect';

const DialogConnect: React.FC<{
  open: boolean;
  loading: boolean;
  onAction: Function;
}> = ({ open, loading, onAction }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const text = useLocale().text;
  const dialogText = text.DIALOGS[id.toUpperCase()];

  return (
    <NuiDialog
      open={open}
      onClose={() => onAction('close', id)}
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          background: globals.colors.bgBase,
        },
      }}>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginBottom={6}>
          <NuiIcon name="logo" size={16} color={globals.colors.white} />
          <NuiTypography variant="headline-bold" gutterBottom>
            {dialogText.TITLE}
          </NuiTypography>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginBottom={6}>
          <NuiTextField
            name="prompt"
            type="text"
            placeholder={dialogText.INPUT_EMAIL}
            value={email}
            InputProps={{
              autoComplete: 'off',
              autoCapitalize: 'off',
              autoCorrect: 'off',
              spellCheck: 'false',
            }}
            onChange={(e: any) => setEmail(e.target.value)}
            sx={{ width: '100%' }}
          />
          <NuiTextField
            name="prompt"
            type="password"
            placeholder={dialogText.INPUT_PASSWORD}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            sx={{ width: '100%' }}
          />
        </Stack>

        <Stack
          direction="column"
          spacing={4}
          alignItems="center"
          justifyContent="center"
          marginBottom={2}>
          {loading ? (
            <NuiCircularProgress size={32} />
          ) : (
            <NuiButton
              color="primary"
              onClick={() => onAction('connect', id, { email, password })}
              sx={(theme) => ({
                '&.MuiButton-sizeMedium': { padding: `0 ${theme.spacing(12)}` },
              })}>
              {dialogText.CTA}
            </NuiButton>
          )}
          <NuiLink
            onClick={() => onAction('forgotpass', id)}
            sx={{ cursor: 'pointer' }}>
            {dialogText.FORGOTPASS}
          </NuiLink>
          <NuiTypography variant="body-demi" color={globals.colors.white}>
            {dialogText.SIGNUP_1}
            <NuiLink
              onClick={() => onAction('signup', id)}
              sx={{ cursor: 'pointer' }}>
              {dialogText.SIGNUP_2}
            </NuiLink>
            {`.`}
          </NuiTypography>
        </Stack>
      </DialogContent>
    </NuiDialog>
  );
};

export default DialogConnect;
