import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { globals } from '../../theme';

export type NuiTextFieldProps = {} & TextFieldProps;

const NuiTextField = styled((props: NuiTextFieldProps) => {
  return <MuiTextField InputLabelProps={{ shrink: false }} {...props} />;
})<NuiTextFieldProps>(({ theme }) => ({
  backgroundColor: globals.colors.white5,
  caretColor: globals.colors.secondaryCyan,
  borderRadius: 4,
  '.MuiInputBase-input': {
    background: 'none',
    border: 'none',
    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  '.MuiInputBase-multiline': {
    padding: 0,
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.MuiInputAdornment-root': {
    marginRight: 0,
  },
  '.Mui-error': {
    border: `1px solid ${globals.colors.semanticErrorLight}`,
  },
  svg: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fill: globals.colors.white80,
  },
}));

export default NuiTextField;
