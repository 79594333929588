import axios from 'axios';
import { deleteCookie, getCookie, setCookie } from './cookieHelper';
import { NapiCookieLabels } from '../@typings/Napi/NapiCookieLabels.enum';
import { CookieExpirePeriod } from '../@typings/Cookies/Cookie.enum';

const apiUrl: string | undefined = process.env.REACT_APP_NAPI_BASE_URL;
const clientId: string | undefined = process.env.REACT_APP_NAPI_CLIENT_ID;
const redirectUri: string | undefined = process.env.REACT_APP_URL;
const headers = {
  'content-type': 'application/json',
};

// Start NAPI OAuth2 process by fetching token should return string
export const requestAuthorizationCode = async () => {
  try {
    deleteAppCookies();
    // generate new string for code challenge
    const codeChallenge = `${Date.now()}${Date.now()}${Date.now()}`;

    // create new cookie for code challenge
    setCookie({
      name: NapiCookieLabels.CODE_CHALLENGE,
      value: codeChallenge,
      expireValue: 1,
      expirePeriod: CookieExpirePeriod.days,
    });

    const url = `${apiUrl}/oauth/authorize?response_type=code&code_challenge_method=plain&code_challenge=${codeChallenge}&client_id=${clientId}&redirect_uri=${redirectUri}`;
    const response = await axios.get(url);
    const params = new URL(response.request.responseURL).searchParams;
    const resCode = params.get('code');
    return resCode ? resCode : '';
  } catch (e) {
    throw e;
  }
};

// After receiving an auth code call this function to get access token
export const requestAccessToken = async (
  authCode: string,
  email: string,
  password: string,
) => {
  try {
    const url = `${apiUrl}/oauth/access_token`;
    const body: NapiRequest = {
      client_id: clientId,
      grant_type: 'authorization_code',
      redirect_uri: redirectUri,
      code_verifier: getCookie(NapiCookieLabels.CODE_CHALLENGE) || '',
      username: email,
      password: password,
      code: authCode,
    };
    const response = await axios.post(url, body, { headers });
    if (response.status === 201) {
      // get json response
      const tokens = response.data;
      // save new cookies
      saveAuthCookies(tokens);
      return { ok: true, token: tokens };
    } else {
      // reject promise with api response
      return { ok: false, response };
    }
  } catch (e: any) {
    throw e;
  }
};

// Refresh the expired access token using the refresh token
export const refreshAccessToken = async () => {
  try {
    const url = `${apiUrl}/oauth/access_token`;
    const body: NapiRefreshRequest = {
      client_id: clientId || '',
      grant_type: 'refresh_token',
      refresh_token: getCookie(NapiCookieLabels.REFRESH_TOKEN) || '',
    };
    const response = await axios.post(url, body, { headers });
    if (response.status === 201) {
      // get json response
      const tokens = response.data;
      // save new cookies
      saveAuthCookies(tokens);

      return { ok: true, token: tokens };
    } else {
      // reject promise with api response
      return { ok: false, response };
    }
  } catch (e) {
    throw e;
  }
};

export const deleteAppCookies = () => {
  // Remove previous cookies on logout
  deleteCookie(NapiCookieLabels.CODE_CHALLENGE);
  deleteCookie(NapiCookieLabels.ACCESS_TOKEN);
  deleteCookie(NapiCookieLabels.REFRESH_TOKEN);
  deleteCookie(NapiCookieLabels.ACCESS_TOKEN_EXPIRATION);
};

export const saveAuthCookies = (token: NapiToken) => {
  // Remove previous cookies
  deleteCookie(NapiCookieLabels.ACCESS_TOKEN);
  deleteCookie(NapiCookieLabels.REFRESH_TOKEN);
  deleteCookie(NapiCookieLabels.ACCESS_TOKEN_EXPIRATION);

  const tokenValidInMinutes = token.expires_in / 60 - 10;

  setCookie({
    name: NapiCookieLabels.ACCESS_TOKEN,
    value: token.access_token,
    expireValue: 13,
    expirePeriod: CookieExpirePeriod.days,
  });

  const date = new Date();

  // accessTokenExpiration token is valid until the access token is invalid
  // if the accessTokenExpiration cookie is null access token has expired
  setCookie({
    name: NapiCookieLabels.ACCESS_TOKEN_EXPIRATION,
    value: date
      .setTime(date.getTime() + tokenValidInMinutes * 60 * 1000)
      .toString(),
    expireValue: tokenValidInMinutes,
    expirePeriod: CookieExpirePeriod.minutes,
  });

  setCookie({
    name: NapiCookieLabels.REFRESH_TOKEN,
    value: token.refresh_token,
    expireValue: 13, // not 14 so that we minimize chance of using expired token
    expirePeriod: CookieExpirePeriod.days,
  });
};

// Fetch user account using the access token from oauth2 flow
export const getNapiAccount = async (
  token: NapiToken,
): Promise<NapiAccount> => {
  try {
    const url = `${apiUrl}/me`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token.access_token}` },
    });
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
};
