const toDataURLBase64 = (url: string) =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

const convertBase64ToBlobImage = async (file: string) => {
  const test: any = await toDataURLBase64(file);
  const byteCharacters = atob(test.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: 'image/jpeg' });
};

type ImageExtensionType = {
  [key: string]: 'jpeg' | 'jpg';
};

const imageExtensions: ImageExtensionType = {
  'image/jpeg': 'jpeg',
};

const transformImageToFormData = async (
  name: string,
  image: any,
  fieldName: string,
) => {
  const blob = await convertBase64ToBlobImage(image);
  const mimeType = blob.type.split(';')[0];
  const imageName = `${name}.${imageExtensions[mimeType]}`;
  const fd = new FormData();
  fd.append(fieldName, blob, imageName);
  return fd;
};

export { transformImageToFormData };
